<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="'Newsletter'">
        <template slot="content" slot-scope="{ setLoading }">
          <Newsletter :url="url" @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import Newsletter from "@/components/Newsletter/Newsletter";

export default {
  name: "DiscountCodeGroupStatistics",
  components: {
    Ibox,
    Newsletter
  },
  data() {
    return {
      excel: true,
      url: `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/newsletter`
    };
  }
};
</script>
