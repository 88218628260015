<template>
  <div>
    <form autocomplete="off" @submit.prevent="handleSubmit">
      <input
        autocomplete="off"
        name="hidden"
        type="text"
        style="display: none"
      />
      <div
        class="row"
        :class="{ 'has-error': errors.has('radioPhoneOrEmail') }"
      >
        <div class="col-12">
          <div
            class="form-check form-check-inline"
            v-if="shop_name === 'neonail'"
          >
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioPhoneOrEmail"
              id="radioPhone"
              v-model="radioPhoneOrEmail"
              value="phone"
            />
            <label class="form-check-label" for="radioPhone">Baza SMS</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioPhoneOrEmail"
              id="radioEmail"
              v-model="radioPhoneOrEmail"
              value="email"
            />
            <label class="form-check-label" for="radioEmail">Baza email</label>
          </div>
        </div>
        <div class="hr-line-dashed"></div>
        <div v-if="radioPhoneOrEmail === 'phone'" class="form-group col-sm-3">
          <div class="form-group col-sm-3"></div>
          <div class="form-check form-check-inline">
            <button
              type="button"
              class="btn btn-outline btn-primary"
              @click="handleSubmit"
            >
              {{ $t("download_csv") }}
            </button>
          </div>
        </div>
        <div v-if="radioPhoneOrEmail === 'email'" class="form-group col-sm-3">
          <div class="form-group col-sm-3"></div>
          <div
            class="form-check form-check-inline"
            v-if="shop_name === 'neonail'"
          >
            <button
              type="button"
              class="btn btn-outline btn-primary"
              @click="handleSubmit"
            >
              {{ $t("download_csv") }}
            </button>
          </div>
          <div
            class="form-check form-check-inline"
            v-if="shop_name === 'neonail-expert-pl'"
          >
            <button
              type="button"
              class="btn btn-outline btn-primary"
              @click="handleSubmitNNExpert"
            >
              {{ $t("download_csv") }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Newsletter",
  data() {
    return {
      radioPhoneOrEmail: 0,
      shop_name: this.$route.params.shop_name,
      filename: `nwl-${this.$route.params.shop_name}-${
        this.$route.params.shop_lang
      }-${this.$moment().format("YYYY-MM-DD")}`
    };
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    urlSmsExport() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/exports/newsletter-phone-numbers-nn`;
    },
    urlEmailExport() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/exports/newsletter-emails`;
    },
    urlNNExpertEmailExport() {
      return `neonail/pl/exports/newsletter-emails-nn-expert`;
    }
  },
  created() {
    this.$emit("loading", false);
  },
  methods: {
    handleSubmit() {
      this.$emit("loading", true);
      this.$http
        .customRequest({
          url:
            this.radioPhoneOrEmail == "phone"
              ? `${this.urlSmsExport}`
              : `${this.urlEmailExport}`,
          method: "POST",
          responseType: "blob"
        })
        .then(response => {
          this.$func.downloadCSVFile(response, this.filename);
          this.$emit("loading", false);
        })
        .catch(err => {
          this.$toastr.error(err);
          this.$emit("loading", false);
        });
    },
    handleSubmitNNExpert() {
      this.$emit("loading", true);
      this.$http
        .customRequest({
          url: this.urlNNExpertEmailExport,
          method: "POST",
          responseType: "blob"
        })
        .then(response => {
          this.$func.downloadCSVFile(response, this.filename);
          this.$emit("loading", false);
        })
        .catch(err => {
          this.$toastr.error(err);
          this.$emit("loading", false);
        });
    }
  }
};
</script>
style>

<style>
.product-label {
  display: flex;
  justify-content: space-between;
  align-dates: center;
}
.v-select input::placeholder {
  color: rgb(167, 167, 167) !important;
}
</style>
